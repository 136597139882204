/*External dependencies */
import { filter, Observable, switchMap } from "rxjs";

/*Local dependencies */
import { setAuthenticatedClient } from "../../../../../../client/graphql";
import { UpdateUserPointsActions, UpdateUserPointsActionsType, updateUserPointsFailed, UpdateUserPointsRequest, updateUserPointsSucceeded } from "./actions";
import { updateUserPointsMutation } from "./queries";






export function updateUserPointsEpic( action$ ): Observable<UpdateUserPointsActionsType> {
  return action$.pipe(
    filter( ( action: UpdateUserPointsRequest ) => action.type === UpdateUserPointsActions.UPDATE_USER_POINTS_REQUEST ),
    switchMap( ( { input }: UpdateUserPointsRequest ) => updateUserPoints( input ).then( updateUserPointsSucceeded ).catch( updateUserPointsFailed ) )
  )
}


async function updateUserPoints( input ) {
  const graphQLClient = await setAuthenticatedClient()

  const result = graphQLClient.mutate( {
    mutation: updateUserPointsMutation,
    variables: {
      input
    }
  } )

  return result
}