import { Device } from "../../createDevice/redux/actions";

export enum GetDeviceActions {
  TOGGLE_GET_DEVICE_STATE = 'TOGGLE_GET_DEVICE_STATE',
  GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST',
  GET_DEVICE_SUCCEEDED = 'GET_DEVICE_SUCCEEDED',
  GET_DEVICE_FAILED = 'GET_DEVICE_FAILED',
}

interface ToggleGetDeviceModalStateType {
  type: GetDeviceActions.TOGGLE_GET_DEVICE_STATE;
}

export interface GetDeviceRequestType {
  type: GetDeviceActions.GET_DEVICE_REQUEST;
  id: string;
}

interface GetDeviceSucceededType {
  type: GetDeviceActions.GET_DEVICE_SUCCEEDED;
  result: Device;
}

interface GetDeviceFailedType {
  type: GetDeviceActions.GET_DEVICE_FAILED;
  error: Error;
}

export type GetDeviceActionsType =
  | ToggleGetDeviceModalStateType
  | GetDeviceRequestType
  | GetDeviceSucceededType
  | GetDeviceFailedType;

export const toggleGetDeviceModalState = () => ({ type: GetDeviceActions.TOGGLE_GET_DEVICE_STATE });

export const getDeviceRequest = (id: string) => ({ type: GetDeviceActions.GET_DEVICE_REQUEST, id });

export const getDeviceSucceeded = (result: object) => ({ type: GetDeviceActions.GET_DEVICE_SUCCEEDED, result });

export const getDeviceFailed = (error: Error) => ({ type: GetDeviceActions.GET_DEVICE_FAILED, error });
