/*External dependencies*/
import { gql } from '@apollo/client';

export const updateDeviceQuery = gql`
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      ... on Terminal {
        id
        __typename
        address
        approvalsCount
        location {
          lat
          lon
        }
        notes
        organization
        registrationDate
        status
        user {
          id
        }
      }
    }
  }
`;
