/*External dependencies*/
import update from 'immutability-helper';

/*Local dependencies*/
import { CreateDeviceActionsTypes } from '../../../devices/createDevice/redux/actions';
import { UpdateUserActions, UpdateUserActionsType } from './actions';

interface InitialState {
  username: string;
  userSurname: string;
  avatar: string;
  isUpdateUserModalOpen: boolean;
  isLoading: boolean;
  userData: object;
}

export const initialState: InitialState = {
  username: '',
  userSurname: '',
  avatar: '',
  isUpdateUserModalOpen: false,
  isLoading: false,
  userData: {},
};

export function updateUserReducer(state = initialState, action: UpdateUserActionsType | CreateDeviceActionsTypes) {
  switch (action.type) {
    case UpdateUserActions.TOGGLE_UPDATE_USER_MODAL_STATE:
      return update(state, {
        isUpdateUserModalOpen: { $set: !state.isUpdateUserModalOpen },
      });

    case UpdateUserActions.ON_CHANGE_USERNAME:
      return update(state, {
        username: { $set: action.username },
      });
    case UpdateUserActions.ON_CHANGE_USERSURNAME:
      return update(state, {
        userSurname: { $set: action.userSurname },
      });
    case UpdateUserActions.ON_CHANGE_AVATAR:
      return update(state, {
        avatar: { $set: action.url },
      });
    case UpdateUserActions.DELETE_AVATAR:
      return update(state, {
        avatar: { $set: '' },
      });
    case UpdateUserActions.UPDATE_USER_REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case UpdateUserActions.UPDATE_USER_SUCCEEDED:
      return update(state, {
        isUpdateUserModalOpen: { $set: false },
        isLoading: { $set: false },
      });
    case UpdateUserActions.UPDATE_USER_FAILED:
      return update(state, {
        isLoading: { $set: false },
      });
    case UpdateUserActions.GET_USER_SUCCEEDED: {
      const fullName = action.payload.username.split(' ');
      return update(state, {
        username: { $set: fullName[0] },
        userSurname: { $set: fullName[1] ?? '' },
        avatar: { $set: action.payload?.avatar },
        userData: { $set: action.payload },
      });
    }

    default:
      return state;
  }
}
