/*External dependencies*/
import { filter, Observable, switchMap } from 'rxjs';

/*Local dependencies*/
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import { ListDevices } from '../../createDevice/redux/types';
import { listDevicesSucceeded } from '../../listDevices/redux/actions';
import { listDevicesQuery } from '../../listDevices/redux/queries';
import {
  DeleteDeviceActions,
  DeleteDeviceActionsType,
  deleteDeviceFailed,
  DeleteDeviceRequestType,
  deleteDeviceSucceeded,
} from './actions';
import { deleteDeviceMutation } from './queries';

export function deleteDeviceEpic( action$ ): Observable<DeleteDeviceActionsType> {
  return action$.pipe(
    filter( ( action: DeleteDeviceRequestType ) => action.type === DeleteDeviceActions.DELETE_DEVICE_REQUEST ),
    switchMap( ( { id, dispatch }: DeleteDeviceRequestType ) =>
      deleteDevice( id, dispatch ).then( deleteDeviceSucceeded ).catch( deleteDeviceFailed ),
    ),
  );
}

async function deleteDevice( id: string, dispatch ) {
  const graphQLClient = await setAuthenticatedClient();

  try {
    const result = await graphQLClient.mutate( {
      mutation: deleteDeviceMutation,
      variables: { input: { id } },
    } );

    const existingData = graphQLClient.cache.readQuery<{ listDevices: ListDevices }>( {
      query: listDevicesQuery,
      variables: {
        input: {}
      }
    } )

    if ( existingData?.listDevices ) {
      const { devices, total } = existingData?.listDevices
      const result = {
        ...existingData.listDevices,
        devices: [...devices.filter( device => device.id !== id )],
        total: total - 1
      }

      graphQLClient.cache.writeQuery( {
        query: listDevicesQuery,
        variables: { input: {} },
        data: {
          listDevices: result
        }
      } )

      dispatch( listDevicesSucceeded( result ) )
    }

    return result;
  } catch ( e ) {
    throw new Error( 'Delete device failed' )
  }
}
