/*External dependencies */
import { ApproveDeviceActionsType } from './../../features/devices/approveDevice/redux/actions';
import { UpdateDeviceActions, UpdateDeviceActionsType } from './../../features/devices/updateDevice/redux/actions';
import update from 'immutability-helper';

/*Local dependencies */
import { ApproveDeviceActions } from '../../features/devices/approveDevice/redux/actions';
import { CreateDeviceActions, CreateDeviceActionsTypes } from '../../features/devices/createDevice/redux/actions';
import { DeleteDeviceActions, DeleteDeviceActionsType } from '../../features/devices/deleteDevice/redux/actions';
import { UpdateUserActions, UpdateUserActionsType } from '../../features/users/updateUser/redux/actions';
import { AuthActions, AuthActionTypes } from './../../auth/redux/action';
import { HeroActions, HeroActionsType } from './actions';

type initialStateType = {
  isSignOutConOpen: boolean;
  isNotification: boolean;
  notificationData: {
    bg: string;
    iconName: string;
    iconColor: string;
    text: string;
    textColor: string;
  };
};

const initialState: initialStateType = {
  isSignOutConOpen: false,
  isNotification: false,
  notificationData: {
    bg: '',
    iconName: 'Success',
    iconColor: '',
    text: '',
    textColor: '',
  },
};

type ActionsType =
  | HeroActionsType
  | AuthActions
  | UpdateUserActionsType
  | CreateDeviceActionsTypes
  | DeleteDeviceActionsType
  | UpdateDeviceActionsType
  | ApproveDeviceActionsType;

export function heroReducer(state = initialState, action: ActionsType) {
  switch (action.type) {
    case HeroActions.TOGGLE_SIGN_OUT_STATE:
      return update(state, {
        isSignOutConOpen: { $set: !state.isSignOutConOpen },
      });
    case HeroActions.TURN_OFF_NOTIFICATION:
      return update(state, {
        isNotification: { $set: false },
      });
    case UpdateUserActions.UPDATE_USER_SUCCEEDED:
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_green' },
          iconName: { $set: 'happy' },
          iconColor: { $set: 'white' },
          text: { $set: 'update_succeeded' },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    case UpdateUserActions.UPDATE_USER_FAILED:
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_red' },
          iconName: { $set: 'warning' },
          iconColor: { $set: 'white' },
          text: {
            $set: action.error.message === 'Username already exists' ? 'there_is_user' : 'error',
          },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    case CreateDeviceActions.CREATE_DEVICE_SUCCEEDED: {
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_green' },
          iconName: { $set: 'terminal' },
          iconColor: { $set: 'white' },
          text: { $set: 'create_succeeded' },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    }
    case CreateDeviceActions.CREATE_DEVICE_FAILED: {
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_red' },
          iconName: { $set: 'warning' },
          iconColor: { $set: 'white' },
          text: { $set: 'create_failed' },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    }
    case CreateDeviceActions.CREATE_DEVICE_DISTANCE_ERROR: {
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_red' },
          iconName: { $set: 'warning' },
          iconColor: { $set: 'white' },
          text: { $set: 'distance_error' },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    }
    case AuthActionTypes.SIGN_OUT_SUCCEEDED:
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_green' },
          iconName: { $set: 'happy' },
          iconColor: { $set: 'white' },
          text: { $set: 'sign_out_succeeded' },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
        isSignOutConOpen: { $set: false },
      });
    case HeroActions.COPIED: {
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_green' },
          iconName: { $set: 'happy' },
          iconColor: { $set: 'white' },
          text: { $set: `copied` },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    }
    case DeleteDeviceActions.DELETE_DEVICE_SUCCEEDED: {
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_green' },
          iconName: { $set: 'terminal' },
          iconColor: { $set: 'white' },
          text: { $set: `delete_succeeded` },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    }
    case DeleteDeviceActions.DELETE_DEVICE_FAILED: {
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_red' },
          iconName: { $set: 'warning' },
          iconColor: { $set: 'white' },
          text: { $set: 'delete_failed' },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    }
    case UpdateDeviceActions.UPDATE_DEVICE_SUCCEEDED:
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_green' },
          iconName: { $set: 'terminal' },
          iconColor: { $set: 'white' },
          text: { $set: `update_device_succeeded` },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    case ApproveDeviceActions.APPROVE_DEVICE_SUCCEEDED:
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_green' },
          iconName: { $set: 'terminal' },
          iconColor: { $set: 'white' },
          text: { $set: `approve_device_succeeded` },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    case ApproveDeviceActions.APPROVE_DEVICE_FAILED:
      return update(state, {
        notificationData: {
          bg: { $set: 'soft_red' },
          iconName: { $set: 'warning' },
          iconColor: { $set: 'white' },
          text: { $set: 'approve_device_failed' },
          textColor: { $set: 'white' },
        },
        isNotification: { $set: true },
      });
    default:
      return state;
  }
}
