export enum SearchActions {
  TOGGLE_SEARCH_STATE = 'TOGGLE_SEARCH_STATE',
  ON_CHANGE_SEARCH = 'ON_CHANGE_SEARCH',
}

export type ToggleSearchStateType = {
  type: SearchActions.TOGGLE_SEARCH_STATE;
};

export type OnChangeSearchType = {
  type: SearchActions.ON_CHANGE_SEARCH;
  value: string;
};

export type SearchActionsType = ToggleSearchStateType | OnChangeSearchType;

export const toggleSearchState = (): ToggleSearchStateType => ({
  type: SearchActions.TOGGLE_SEARCH_STATE,
});

export const onChangeSearch = (value: string): OnChangeSearchType => ({
  type: SearchActions.ON_CHANGE_SEARCH,
  value,
});
