/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';


/* Local dependencies */
import {
  confirmForgotPasswordEpic,
  forgotPasswordEpic,
  initialUserEpic,
  signInEpic,
  signOutEpic,
  signUpConfirmPincode,
  signUpEpic,
} from '../components/webApp/auth/redux/epics';
import { AuthReducer } from '../components/webApp/auth/redux/reducer';
import { createDeviceEpic } from '../components/webApp/features/devices/createDevice/redux/epics';
import { createDeviceReducer } from '../components/webApp/features/devices/createDevice/redux/reducer';
import { listDevicesEpic } from '../components/webApp/features/devices/listDevices/redux/epics';
import { listDevicesReducer } from '../components/webApp/features/devices/listDevices/redux/reducer';
import { getUserEpic } from '../components/webApp/features/users/getUser/redux/epics';
import { listUsersEpic } from '../components/webApp/features/users/listUsers/redux/epics';
import { listUsersReducer } from '../components/webApp/features/users/listUsers/redux/reducer';
import { updateUserEpic } from '../components/webApp/features/users/updateUser/redux/epics';
import { updateUserReducer } from '../components/webApp/features/users/updateUser/redux/reducer';
import { heroReducer } from '../components/webApp/hero/redux/reducer';
import { getDeviceEpic } from '../components/webApp/features/devices/getDevice/redux/epics';
import { getDeviceReducer } from '../components/webApp/features/devices/getDevice/redux/reducer';
import { updateDeviceEpic } from '../components/webApp/features/devices/updateDevice/redux/epics';
import { deleteDeviceEpic } from '../components/webApp/features/devices/deleteDevice/redux/epics';
import { deleteDeviceReducer } from '../components/webApp/features/devices/deleteDevice/redux/reducer';
import { approveDeviceEpic } from '../components/webApp/features/devices/approveDevice/redux/epics';
import { searchReducer } from '../components/webApp/hero/searchBar/redux/reducer';
import { updateUserPointsEpic } from '../components/webApp/features/users/updateUserPoints/redux/epics';

const rootEpic = combineEpics(
  signUpEpic,
  signUpConfirmPincode,
  signInEpic,
  signOutEpic,
  initialUserEpic,
  forgotPasswordEpic,
  confirmForgotPasswordEpic,
  getUserEpic,
  listUsersEpic,
  updateUserEpic,
  createDeviceEpic,
  listDevicesEpic,
  getDeviceEpic,
  updateDeviceEpic,
  deleteDeviceEpic,
  approveDeviceEpic,
  updateUserPointsEpic
);

const rootReducer = combineReducers({
  AuthReducer,
  listUsersReducer,
  updateUserReducer,
  heroReducer,
  createDeviceReducer,
  listDevicesReducer,
  getDeviceReducer,
  deleteDeviceReducer,
  searchReducer
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
