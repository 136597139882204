export enum DeleteDeviceActions {
  TOGGLE_DELETE_DEVICE_MODAL = 'TOGGLE_DELETE_DEVICE_MODAL',
  DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCEEDED = 'DELETE_DEVICE_SUCCEEDED',
  DELETE_DEVICE_FAILED = 'DELETE_DEVICE_FAILED',
}

export type ToggleDeleteDeviceModalType = {
  type: DeleteDeviceActions.TOGGLE_DELETE_DEVICE_MODAL;
};

export type DeleteDeviceRequestType = {
  type: DeleteDeviceActions.DELETE_DEVICE_REQUEST;
  id: string;
  dispatch: ( object ) => void
};

export type DeleteDeviceSucceededType = {
  type: DeleteDeviceActions.DELETE_DEVICE_SUCCEEDED;
  result: object;
};

export type DeleteDeviceFailedType = {
  type: DeleteDeviceActions.DELETE_DEVICE_FAILED;
  error: Error;
};

export type DeleteDeviceActionsType =
  | ToggleDeleteDeviceModalType
  | DeleteDeviceRequestType
  | DeleteDeviceSucceededType
  | DeleteDeviceFailedType;

export const toggleDeleteDeviceModal = () => ( {
  type: DeleteDeviceActions.TOGGLE_DELETE_DEVICE_MODAL,
} );

export const deleteDeviceRequest = ( id: string, dispatch ): DeleteDeviceRequestType => ( {
  type: DeleteDeviceActions.DELETE_DEVICE_REQUEST,
  id,
  dispatch
} );

export const deleteDeviceSucceeded = ( result: object ): DeleteDeviceSucceededType => ( {
  type: DeleteDeviceActions.DELETE_DEVICE_SUCCEEDED,
  result,
} );

export const deleteDeviceFailed = ( error: Error ): DeleteDeviceFailedType => ( {
  type: DeleteDeviceActions.DELETE_DEVICE_FAILED,
  error,
} );
