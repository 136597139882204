/*External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { SearchActions, SearchActionsType } from './actions';

type InitialStateType = {
  value: string;
  isOpenSearchModal: boolean;
};

const initialState: InitialStateType = {
  value: '',
  isOpenSearchModal: false,
};

export function searchReducer(state = initialState, action: SearchActionsType): InitialStateType {
  switch (action.type) {
    case SearchActions.TOGGLE_SEARCH_STATE:
      return update(state, {
        isOpenSearchModal: { $set: !state.isOpenSearchModal },
      });
    case SearchActions.ON_CHANGE_SEARCH:
      return update(state, {
        value: { $set: action.value },
      });
    default:
      return state;
  }
}
