/*External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { DeleteDeviceActions, DeleteDeviceActionsType } from '../../deleteDevice/redux/actions';
import { GetDeviceActions, GetDeviceActionsType } from '../../getDevice/redux/actions';
import { UpdateDeviceActions, UpdateDeviceActionsType } from '../../updateDevice/redux/actions';
import { CreateDeviceActions, CreateDeviceActionsTypes, Device } from './actions';

type InitialStateType = {
  isOpenCreateDeviceModal: boolean;
  isUpdateDevice: boolean;
  isLoading: boolean;
  organization: string;
  geolocation: {
    loaded: boolean;
    coordinates: {
      lat: string;
      lng: string;
    };
  };
  deviceData: {
    device: Device | any;
    coordinates: string[];
    address: object;
  };
};

export const initialState: InitialStateType = {
  isOpenCreateDeviceModal: false,
  isUpdateDevice: false,
  isLoading: false,
  organization: '',
  geolocation: {
    loaded: false,
    coordinates: { lat: '', lng: '' },
  },
  deviceData: {
    device: {
      address: '',
      organization: '',
      notes: '',
    },
    coordinates: [],
    address: {},
  },
};

export function createDeviceReducer(
  state = initialState,
  action: CreateDeviceActionsTypes | UpdateDeviceActionsType | GetDeviceActionsType | DeleteDeviceActionsType,
) {
  switch (action.type) {
    case CreateDeviceActions.TOGGLE_CREATE_DEVICE_STATE:
      return update(state, {
        isOpenCreateDeviceModal: { $set: !state.isOpenCreateDeviceModal },
        isUpdateDevice: { $set: action.modal === 'createDevice' ? false : state.isUpdateDevice },
      });
    case CreateDeviceActions.SET_GEOLOCATION:
      return update(state, {
        geolocation: {
          loaded: { $set: true },
          coordinates: {
            lat: { $set: action.geolocation.lat },
            lng: { $set: action.geolocation.lng },
          },
        },
      });
    case CreateDeviceActions.SET_DEVICE_DATA:
      return update(state, {
        deviceData: {
          coordinates: { $set: action.data.coordinates },
          address: { $set: action.data.address },
          device: {
            address: { $set: Object.values(action.data.address).join(' ').trim() },
          },
        },
      });
    case CreateDeviceActions.DELETE_DEVICE_DATA:
      return update(state, {
        deviceData: {
          address: { $set: initialState.deviceData.address },
          device: { $set: initialState.deviceData.device },
        },
      });
    case CreateDeviceActions.ON_CHANGE_ORGANIZATION:
      return update(state, {
        deviceData: {
          device: {
            organization: { $set: action.organization },
          },
        },
      });
    case CreateDeviceActions.ON_CHANGE_ADDRESS:
      return update(state, {
        deviceData: {
          device: {
            address: { $set: action.address },
          },
        },
      });
    case CreateDeviceActions.ON_CHANGE_NOTES:
      return update(state, {
        deviceData: {
          device: {
            notes: { $set: action.notes },
          },
        },
      });
    case CreateDeviceActions.CREATE_DEVICE_REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case CreateDeviceActions.CREATE_DEVICE_SUCCEEDED:
      return update(state, {
        isLoading: { $set: false },
        isOpenCreateDeviceModal: { $set: false },
        deviceData: { $set: initialState.deviceData },
      });
    case CreateDeviceActions.CREATE_DEVICE_FAILED:
      return update(state, {
        isLoading: { $set: false },
      });
    case UpdateDeviceActions.UPDATE_DEVICE_REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case UpdateDeviceActions.UPDATE_DEVICE_SUCCEEDED:
      return update(state, {
        isLoading: { $set: false },
      });
    case UpdateDeviceActions.UPDATE_DEVICE_FAILED:
      return update(state, {
        isLoading: { $set: false },
      });
    case GetDeviceActions.GET_DEVICE_SUCCEEDED:
      return update(state, {
        deviceData: {
          device: { $set: action.result },
          coordinates: { $set: [action.result.location.lat, action.result.location.lon] },
        },
      });
    case UpdateDeviceActions.SET_UPDATE_DEVICE:
      return update(state, {
        isUpdateDevice: { $set: true },
      });
    case DeleteDeviceActions.DELETE_DEVICE_SUCCEEDED:
      return update(state, {
        isOpenCreateDeviceModal: { $set: false },
      });
    default:
      return state;
  }
}
