/*External dependencies*/
import update from 'immutability-helper';

/*Local dependencies*/
import { ListDevicesActions, ListDevicesActionsType } from './actions';

type InitialStateType = {
  isListDevicesLoading: boolean;
  total: number;
  devices: Array<object>;
};

export const initialState: InitialStateType = {
  isListDevicesLoading: false,
  total: 0,
  devices: [],
};

export function listDevicesReducer(state = initialState, action: ListDevicesActionsType): InitialStateType {
  switch (action.type) {
    case ListDevicesActions.LIST_DEVICES_REQUEST:
      return update(state, {
        isListDevicesLoading: { $set: true },
      });
    case ListDevicesActions.LIST_DEVICES_SUCCEEDED:
      return update(state, {
        devices: { $set: action.result.devices },
        total: { $set: action.result.total },
        isListDevicesLoading: { $set: false },
      });
    case ListDevicesActions.LIST_DEVICES_FAILED:
      return update(state, {
        isListDevicesLoading: { $set: false },
      });
    default:
      return state;
  }
}
