/*External dependencies*/
import { filter, switchMap } from 'rxjs';

/*Local dependencies*/
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import { CreateDeviceInput } from '../../createDevice/redux/actions';
import { UpdateDeviceActions, updateDeviceFailed, UpdateDeviceRequestType, updateDeviceSucceeded } from './actions';
import { updateDeviceQuery } from './queries';

export function updateDeviceEpic(action$) {
  return action$.pipe(
    filter((action: UpdateDeviceRequestType) => action.type === UpdateDeviceActions.UPDATE_DEVICE_REQUEST),
    switchMap(({ input }: UpdateDeviceRequestType) =>
      updateDevice(input).then(updateDeviceSucceeded).catch(updateDeviceFailed),
    ),
  );
}

async function updateDevice(input: CreateDeviceInput) {
  const graphQLClient = await setAuthenticatedClient();

  console.log('input ---> ', input);

  const {
    data: { updateDevice: newDevice },
  } = await graphQLClient.mutate({
    mutation: updateDeviceQuery,
    variables: { input: input },
  });

  return newDevice;
}
