/*External dependencies*/
import { gql } from "@apollo/client";

export const getUserQuery = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      email
      avatar
      __typename
      groupName
      id
      joinDate
      point
      status
      phoneNumber
      username
    }
  }
`;
