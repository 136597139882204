/*External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { DeleteDeviceActions } from './actions';

type initialStateType = {
  isDeleting: boolean;
  isOpenDeleteDevice: boolean;
};

export const initialState: initialStateType = {
  isDeleting: false,
  isOpenDeleteDevice: false,
};

export function deleteDeviceReducer(state = initialState, action) {
  switch (action.type) {
    case DeleteDeviceActions.TOGGLE_DELETE_DEVICE_MODAL:
      return update(state, {
        isOpenDeleteDevice: { $set: !state.isOpenDeleteDevice },
      });
    case DeleteDeviceActions.DELETE_DEVICE_REQUEST:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DeleteDeviceActions.DELETE_DEVICE_SUCCEEDED:
      return update(state, {
        isDeleting: { $set: false },
        isOpenDeleteDevice: { $set: false },
      });
    case DeleteDeviceActions.DELETE_DEVICE_FAILED:
      return update(state, {
        isDeleting: { $set: false },
        isOpenDeleteDevice: { $set: false },
      });
    default:
      return state;
  }
}
