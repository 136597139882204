/*External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { ApproveDeviceActions } from '../../approveDevice/redux/actions';
import { CreateDeviceActions, Device } from '../../createDevice/redux/actions';
import { DeleteDeviceActions } from '../../deleteDevice/redux/actions';
import { GetDeviceActions } from './actions';

type initialStateType = {
  isOpenGetDeviceModal: boolean;
  isGetDeviceLoading: boolean;
  isApproving: boolean;
  device: Device;
  deviceId: string;
  approvedUsers: []
};

export const initialState: initialStateType = {
  isOpenGetDeviceModal: false,
  isGetDeviceLoading: false,
  isApproving: false,
  deviceId: '',
  approvedUsers: [],
  device: {
    id: '',
    __typename: '',
    approvalsCount: 0,
    address: '',
    location: {
      lat: 0,
      lon: 0,
    },
    notes: '',
    organization: '',
    registrationDate: '',
    status: '',
    user: {
      id: '',
    },
  },
};

export function getDeviceReducer( state = initialState, action ) {
  switch ( action.type ) {
    case GetDeviceActions.TOGGLE_GET_DEVICE_STATE:
      return update( state, {
        isOpenGetDeviceModal: { $set: !state.isOpenGetDeviceModal },
      } );
    case GetDeviceActions.GET_DEVICE_REQUEST:
      return update( state, {
        isOpenGetDeviceModal: { $set: true },
        isGetDeviceLoading: { $set: true },
        deviceId: { $set: action.id },
      } );
    case GetDeviceActions.GET_DEVICE_SUCCEEDED:
      const response = action.result
      const organizationData = response.organization.split( "|" )
      const organization = organizationData[0]
      const device = {
        ...response,
        organization
      }
      console.log( 'get device reducer device -->', device )
      return update( state, {
        device: { $set: device },
        approvedUsers: { $set: organizationData.slice( 1 ) },
        isGetDeviceLoading: { $set: false },
      } );
    case GetDeviceActions.GET_DEVICE_FAILED:
      return update( state, {
        device: { $set: null },
        isGetDeviceLoading: { $set: false },
      } );
    case CreateDeviceActions.SET_DEVICE_DATA:
      return update( state, {
        deviceId: { $set: '' },
      } );
    case DeleteDeviceActions.DELETE_DEVICE_SUCCEEDED:
      return update( state, {
        isOpenGetDeviceModal: { $set: false },
      } );
    case ApproveDeviceActions.APPROVE_DEVICE_REQUEST:
      return update( state, {
        isApproving: { $set: true },
      } );
    case ApproveDeviceActions.APPROVE_DEVICE_SUCCEEDED:
      return update( state, {
        isApproving: { $set: false },
        device: { $set: action.payload },
      } );
    case ApproveDeviceActions.APPROVE_DEVICE_FAILED:
      return update( state, {
        isApproving: { $set: false },
        isOpenGetDeviceModal: { $set: false },
      } );
    default:
      return state;
  }
}
