/*External dependencies*/
import update from 'immutability-helper';
import { ListUsersActions, ListUsersActionsType } from './actions';

interface ListUsersStateType {
  isListUsersModalOpen: boolean;
  isLoading: boolean;
  usersAll: object;
}

export const initialState: ListUsersStateType = {
  isListUsersModalOpen: false,
  isLoading: false,
  usersAll: {},
};

export function listUsersReducer(state = initialState, action: ListUsersActionsType) {
  switch (action.type) {
    case ListUsersActions.TOGGLE_LIST_USERS_MODAL_STATE:
      return update(state, {
        isListUsersModalOpen: { $set: !state.isListUsersModalOpen },
      });
    case ListUsersActions.LIST_USERS_REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case ListUsersActions.LIST_USERS_SUCCEEDED:
      return update(state, {
        usersAll: { $set: action.payload },
        isLoading: { $set: false },
      });
    case ListUsersActions.LIST_USERS_FAILED:
      return update(state, {
        isLoading: { $set: false },
      });
    default:
      return state;
  }
}
