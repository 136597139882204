/*External dependencies */
import { filter, switchMap } from 'rxjs';

/*Local dependencies */
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import {
    ListUsersActions,
    ListUsersActionsType,
    listUsersFailed,
    ListUsersRequestType,
    listUsersSucceeded,
} from './actions';
import { listUsersQuery } from './queries';

export function listUsersEpic(action$) {
  return action$.pipe(
    filter((action: ListUsersActionsType) => {
      return action.type === ListUsersActions.LIST_USERS_REQUEST;
    }),
    switchMap(({ filter }: ListUsersRequestType) => listUsers(filter).then(listUsersSucceeded).catch(listUsersFailed)),
  );
}

export async function listUsers({ filter }: any): Promise<any> {
  const graphqlQlClient = await setAuthenticatedClient();

  const {
    data: {
      listUsers: { users, total },
    },
  } = await graphqlQlClient.query({
    query: listUsersQuery,
    variables: {
      input: {
        filter,
      },
    },
  });

  return {total, users}
}
