export enum HeroActions {
  TOGGLE_SIGN_OUT_STATE = 'TOGGLE_SIGN_OUT_STATE',
  TURN_OFF_NOTIFICATION = 'TURN_OFF_NOTIFICATION',
  COPIED = 'COPIED',
}

interface ToggleSignOutStateType {
  type: HeroActions.TOGGLE_SIGN_OUT_STATE;
}

interface TurnOffNotificationType {
  type: HeroActions.TURN_OFF_NOTIFICATION;
}

interface CopiedType {
  type: HeroActions.COPIED;
  name: string;
}

export type HeroActionsType = ToggleSignOutStateType | TurnOffNotificationType | CopiedType;

export const toggleSignOutState = () => ({
  type: HeroActions.TOGGLE_SIGN_OUT_STATE,
});

export const turnOffNotification = () => ({ type: HeroActions.TURN_OFF_NOTIFICATION });

export const copied = (name: string) => ({ type: HeroActions.COPIED, name });
