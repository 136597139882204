/*External dependencies */
import { gql } from '@apollo/client';

export const createDeviceQuery = gql`
mutation CreateDevice($input: CreateDeviceInput!){
  createDevice(input: $input)  {
    ... on Terminal {
      id
      __typename
      address
      approvalsCount
      location {
        lat
        lon
      }
      notes
      organization
      registrationDate
      status
      user {
        id
      }
    }
  }
}
`;
