// External dependencies
import { gql } from '@apollo/client';

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      avatar
      email
      groupName
      id
      joinDate
      phoneNumber
      point
      status
      username
      __typename
    }
  }
`;
