/*External dependencies*/
import { gql } from '@apollo/client';

export const listDevicesQuery = gql`
  query ListDevices($input: ListDevicesInput!) {
    listDevices(input: $input) {
      devices {
        ... on Terminal {
          address
          approvalsCount
          id
          location {
            lat
            lon
          }
          notes
          organization
          registrationDate
          status
          user {
            id
          }
          __typename
        }
      }
      total
    }
  }
`;
