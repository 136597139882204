/*External dependencies*/
import { filter, switchMap } from 'rxjs';

/*Local dependencies*/
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import { GetDeviceActions, getDeviceFailed, GetDeviceRequestType, getDeviceSucceeded } from './actions';
import { getDeviceQuery } from './queries';

export function getDeviceEpic(action$) {
  return action$.pipe(
    filter((action: GetDeviceRequestType) => action.type === GetDeviceActions.GET_DEVICE_REQUEST),
    switchMap(({ id }: GetDeviceRequestType) => getDevice(id).then(getDeviceSucceeded).catch(getDeviceFailed)),
  );
}

async function getDevice(id: string): Promise<object> {
  console.log('idd in get device', id);
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { getDevice: device },
  } = await graphQLClient.query({
    query: getDeviceQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return device;
}
