/*External dependencies */
import { filter, switchMap } from 'rxjs';

/*Local dependencies */
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import { ApproveDeviceActions, approveDeviceFailed, ApproveDeviceRequestType, approveDeviceSucceeded } from './actions';
import { approveDeviceMutation } from './queries';

export function approveDeviceEpic(action$) {
  return action$.pipe(
    filter((action: ApproveDeviceRequestType) => action.type === ApproveDeviceActions.APPROVE_DEVICE_REQUEST),
    switchMap(({ input }: ApproveDeviceRequestType) =>
      approveDevice(input).then(approveDeviceSucceeded).catch(approveDeviceFailed),
    ),
  );
}

async function approveDevice(input) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { approveDevice: device },
  } = await graphQLClient.mutate({
    mutation: approveDeviceMutation,
    variables: {
      input: input,
    },
  });

  return device;
}
