/*Local dependencies*/
import { DeviceTypes } from '../../../users/types';

export enum CreateDeviceActions {
  CREATE_DEVICE_REQUEST = 'CREATE_DEVICE_REQUEST',
  CREATE_DEVICE_SUCCEEDED = 'CREATE_DEVICE_SUCCEEDED',
  CREATE_DEVICE_FAILED = 'CREATE_DEVICE_FAILED',
  CREATE_DEVICE_DISTANCE_ERROR = 'CREATE_DEVICE_DISTANCE_ERROR',
  SET_GEOLOCATION = 'SET_GEOLOCATION',
  SET_DEVICE_DATA = 'SET_DEVICE_DATA',
  DELETE_DEVICE_DATA = 'DELETE_DEVICE_DATA',
  TOGGLE_CREATE_DEVICE_STATE = 'TOGGLE_CREATE_DEVICE_STATE',
  ON_CHANGE_ORGANIZATION = 'ON_CHANGE_ORGANIZATION',
  ON_CHANGE_ADDRESS = 'ON_CHANGE_ADDRESS',
  ON_CHANGE_NOTES = 'ON_CHANGE_NOTES',
}

export interface CreateDeviceInput {
  address: string;
  location: {
    lat: number;
    lon: number;
  };
  notes: string;
  organization: string;
  deviceType: DeviceTypes.DEVICE;
  userLocation?: {
    lat: number;
    lon: number;
  };
}

export interface Device {
  id: string;
  __typename: string;
  address: string;
  approvalsCount: number;
  location: {
    lat: number;
    lon: number;
  };
  notes: string;
  organization: string;
  registrationDate: string;
  status: string;
  user: {
    id: string;
  };
}

export type Location = {
  lat: string;
  lng: string;
};

export type CreateDeviceRequestType = {
  type: CreateDeviceActions.CREATE_DEVICE_REQUEST;
  input: CreateDeviceInput;
  dispatch: ( object ) => void
};

export type CreateDeviceSucceededType = {
  type: CreateDeviceActions.CREATE_DEVICE_SUCCEEDED;
  payload: Device;
};

export type CreateDeviceFailedType = {
  type: CreateDeviceActions.CREATE_DEVICE_FAILED;
  error: Error;
};

export type CreateDeviceDistanceErrorType = {
  type: CreateDeviceActions.CREATE_DEVICE_DISTANCE_ERROR;
};

type SetGeolocationType = {
  type: CreateDeviceActions.SET_GEOLOCATION;
  geolocation: Location;
};

type SetDeviceDataType = {
  type: CreateDeviceActions.SET_DEVICE_DATA;
  data: {
    coordinates: string[];
    address: object;
  };
};

type DeleteDeviceDataType = {
  type: CreateDeviceActions.DELETE_DEVICE_DATA;
};

type ToggleCreateDeviceStateType = {
  type: CreateDeviceActions.TOGGLE_CREATE_DEVICE_STATE;
  modal: string;
};

type OnChangeOrganizationType = {
  type: CreateDeviceActions.ON_CHANGE_ORGANIZATION;
  organization: string;
};

type OnChangeAddressType = {
  type: CreateDeviceActions.ON_CHANGE_ADDRESS;
  address: string;
};

type OnChangeNotesType = {
  type: CreateDeviceActions.ON_CHANGE_NOTES;
  notes: string;
};

export type CreateDeviceActionsTypes =
  | CreateDeviceRequestType
  | CreateDeviceSucceededType
  | CreateDeviceFailedType
  | CreateDeviceDistanceErrorType
  | SetGeolocationType
  | SetDeviceDataType
  | DeleteDeviceDataType
  | ToggleCreateDeviceStateType
  | OnChangeOrganizationType
  | OnChangeAddressType
  | OnChangeNotesType;

export const createDeviceRequest = ( input: any, dispatch ): CreateDeviceRequestType => ( {
  type: CreateDeviceActions.CREATE_DEVICE_REQUEST,
  input,
  dispatch
} );

export const createDeviceSucceeded = ( payload: Device ): CreateDeviceSucceededType => ( {
  type: CreateDeviceActions.CREATE_DEVICE_SUCCEEDED,
  payload,
} );

export const createDeviceFailed = ( error: Error ): CreateDeviceFailedType => ( {
  type: CreateDeviceActions.CREATE_DEVICE_FAILED,
  error,
} );

export const createDeviceDistanceError = (): CreateDeviceDistanceErrorType => ( {
  type: CreateDeviceActions.CREATE_DEVICE_DISTANCE_ERROR,
} );

export const setGeolocation = ( geolocation: Location ): SetGeolocationType => ( {
  type: CreateDeviceActions.SET_GEOLOCATION,
  geolocation,
} );

export const setDeviceData = ( data: { coordinates: string[]; address: object } ): SetDeviceDataType => ( {
  type: CreateDeviceActions.SET_DEVICE_DATA,
  data,
} );

export const deleteDeviceData = (): DeleteDeviceDataType => ( { type: CreateDeviceActions.DELETE_DEVICE_DATA } );

export const toggleCreateDeviceState = ( modal: string ): ToggleCreateDeviceStateType => ( {
  type: CreateDeviceActions.TOGGLE_CREATE_DEVICE_STATE,
  modal,
} );

export const onChangeOrganization = ( organization: string ): OnChangeOrganizationType => ( {
  type: CreateDeviceActions.ON_CHANGE_ORGANIZATION,
  organization,
} );

export const onChangeAddress = ( address: string ): OnChangeAddressType => ( {
  type: CreateDeviceActions.ON_CHANGE_ADDRESS,
  address,
} );

export const onChangeNotes = ( notes: string ): OnChangeNotesType => ( {
  type: CreateDeviceActions.ON_CHANGE_NOTES,
  notes,
} );
