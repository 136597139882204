/*External dependencies */
import { filter, switchMap } from 'rxjs';

/*Local dependencies */
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import { GetUserActions, GetUserActionsType, getUserFailed, GetUserRequestType, getUserSucceeded } from './actions';
import { getUserQuery } from './queries';

export function getUserEpic(action$) {
  return action$.pipe(
    filter((action: GetUserActionsType) => action.type === GetUserActions.GET_USER_REQUEST),
    switchMap(({ id }: GetUserRequestType) => getUser(id).then(getUserSucceeded).catch(getUserFailed)),
  );
}

export async function getUser(input) {
  console.log('input --> ', input)
  const graphQlClient = await setAuthenticatedClient();

  const {
    data: { getUser: user },
  } = await graphQlClient.query({
    query: getUserQuery,
    variables: { input: { id: input } },
  });

  return user;
}
