export enum UpdateUserPointsActions {
  UPDATE_USER_POINTS_REQUEST = "UPDATE_USER_POINTS_REQUEST",
  UPDATE_USER_POINTS_SUCCEEDED = "UPDATE_USER_POINTS_SUCCEEDED",
  UPDATE_USER_POINTS_FAILED = "UPDATE_USER_POINTS_FAILED"
}

export type UpdateUserPointsInput = {
  id: string,
  userPoints: number,
}

export interface UpdateUserPointsRequest {
  type: UpdateUserPointsActions.UPDATE_USER_POINTS_REQUEST,
  input: UpdateUserPointsInput
}

export interface UpdateUserPointsSucceeded {
  type: UpdateUserPointsActions.UPDATE_USER_POINTS_SUCCEEDED,
  response: UpdateUserPointsInput
}

export interface UpdateUserPointsFailed {
  type: UpdateUserPointsActions.UPDATE_USER_POINTS_FAILED,
  error: Error
}

export type UpdateUserPointsActionsType = UpdateUserPointsRequest | UpdateUserPointsSucceeded | UpdateUserPointsFailed

export const updateUserPointsRequest = ( input: UpdateUserPointsInput ): UpdateUserPointsRequest => ( { type: UpdateUserPointsActions.UPDATE_USER_POINTS_REQUEST, input } )

export const updateUserPointsSucceeded = ( response: any ): UpdateUserPointsSucceeded => ( { type: UpdateUserPointsActions.UPDATE_USER_POINTS_SUCCEEDED, response } )

export const updateUserPointsFailed = ( error: Error ): UpdateUserPointsFailed => ( { type: UpdateUserPointsActions.UPDATE_USER_POINTS_FAILED, error } )
