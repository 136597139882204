export enum ListDevicesActions {
  LIST_DEVICES_REQUEST = 'LIST_DEVICES_REQUEST',
  LIST_DEVICES_SUCCEEDED = 'LIST_DEVICES_SUCCEEDED',
  LIST_DEVICES_FAILED = 'LIST_DEVICES_FAILED',
}

export interface ListDevicesRequestType {
  type: ListDevicesActions.LIST_DEVICES_REQUEST;
  input;
}

type resultType = {
  total: number;
  devices: Array<object>;
};

export interface ListDevicesSucceededType {
  type: ListDevicesActions.LIST_DEVICES_SUCCEEDED;
  result: resultType;
}

export interface ListDevicesFailedType {
  type: ListDevicesActions.LIST_DEVICES_FAILED;
  error: Error;
}

export type ListDevicesActionsType = ListDevicesRequestType | ListDevicesSucceededType | ListDevicesFailedType;

export const listDevicesRequest = (input: object) => ({
  type: ListDevicesActions.LIST_DEVICES_REQUEST,
  input,
});

export const listDevicesSucceeded = (result: resultType) => ({
  type: ListDevicesActions.LIST_DEVICES_SUCCEEDED,
  result,
});

export const listDevicesFailed = (error: Error) => ({
  type: ListDevicesActions.LIST_DEVICES_FAILED,
  error,
});
