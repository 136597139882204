/*Local dependencies*/
import { UpdateDeviceInputType } from '../../../users/types';
import { Device } from '../../createDevice/redux/actions';

export enum UpdateDeviceActions {
  SET_UPDATE_DEVICE = 'SET_UPDATE_DEVICE',
  UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST',
  UPDATE_DEVICE_SUCCEEDED = 'UPDATE_DEVICE_SUCCEEDED',
  UPDATE_DEVICE_FAILED = 'UPDATE_DEVICE_FAILED',
}

export interface SetUpdateDeviceType {
  type: UpdateDeviceActions.SET_UPDATE_DEVICE;
}

export interface UpdateDeviceRequestType {
  type: UpdateDeviceActions.UPDATE_DEVICE_REQUEST;
  id: string;
  input: any;
}

export interface UpdateDeviceSucceededType {
  type: UpdateDeviceActions.UPDATE_DEVICE_SUCCEEDED;
  result: Device;
}

export interface UpdateDeviceFailedType {
  type: UpdateDeviceActions.UPDATE_DEVICE_FAILED;
  error: Error;
}

export type UpdateDeviceActionsType =
  | SetUpdateDeviceType
  | UpdateDeviceRequestType
  | UpdateDeviceSucceededType
  | UpdateDeviceFailedType;

export const setUpdateDevice = () => ({
  type: UpdateDeviceActions.SET_UPDATE_DEVICE,
});

export const updateDeviceRequest = (input: UpdateDeviceInputType) => ({
  type: UpdateDeviceActions.UPDATE_DEVICE_REQUEST,
  input,
});

export const updateDeviceSucceeded = (result: Device) => ({
  type: UpdateDeviceActions.UPDATE_DEVICE_SUCCEEDED,
  result,
});

export const updateDeviceFailed = (error: Error) => ({
  type: UpdateDeviceActions.UPDATE_DEVICE_FAILED,
  error,
});
