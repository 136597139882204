/*External dependencies */
import { filter, Observable, switchMap } from 'rxjs';

/*Local dependencies */
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import { listDevicesSucceeded } from '../../listDevices/redux/actions';
import { listDevicesQuery } from '../../listDevices/redux/queries';
import {
  CreateDeviceActions,
  CreateDeviceActionsTypes,
  createDeviceFailed,
  CreateDeviceRequestType,
  createDeviceSucceeded,
} from './actions';
import { createDeviceQuery } from './queries';
import { ListDevices } from './types';

export function createDeviceEpic( action$ ): Observable<any> {
  return action$.pipe(
    filter( ( action: CreateDeviceActionsTypes ) => action.type === CreateDeviceActions.CREATE_DEVICE_REQUEST ),
    switchMap( ( { input, dispatch }: CreateDeviceRequestType ) =>
      createDevice( input, dispatch ).then( createDeviceSucceeded ).catch( createDeviceFailed ),
    ),
  );
}

export async function createDevice( input, dispatch ) {
  const graphQLClient = await setAuthenticatedClient();

  try {
    const {
      data: { createDevice: device },
    } = await graphQLClient.mutate( {
      mutation: createDeviceQuery,
      variables: { input },
    } );

    const existingData = graphQLClient.cache.readQuery<{ listDevices: ListDevices }>( {
      query: listDevicesQuery,
      variables: {
        input: {}
      }
    } )

    if ( existingData?.listDevices ) {
      const { devices, total } = existingData?.listDevices
      const result = {
        ...existingData.listDevices,
        devices: [...devices, device],
        total: total + 1
      }

      graphQLClient.cache.writeQuery( {
        query: listDevicesQuery,
        variables: { input: {} },
        data: {
          listDevices: result
        }
      } )

      dispatch( listDevicesSucceeded( result ) )
    }


    return device;

  } catch ( e ) {
    throw  new Error( 'some error' )
  }

}
