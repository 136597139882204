/*Local dependencies*/
import { Device } from '../../createDevice/redux/actions';

export enum ApproveDeviceActions {
  APPROVE_DEVICE_REQUEST = 'APPROVE_DEVICE_REQUEST',
  APPROVE_DEVICE_SUCCEEDED = 'APPROVE_DEVICE_SUCCEEDED',
  APPROVE_DEVICE_FAILED = 'APPROVE_DEVICE_FAILED',
}

export interface ApproveDeviceRequestType {
  type: ApproveDeviceActions.APPROVE_DEVICE_REQUEST;
  input: object;
}

export type ApproveDeviceSucceededType = {
  type: ApproveDeviceActions.APPROVE_DEVICE_SUCCEEDED;
  payload: Device;
};

export type ApproveDeviceFailedType = {
  type: ApproveDeviceActions.APPROVE_DEVICE_FAILED;
  error: Error;
};

export type ApproveDeviceActionsType = ApproveDeviceRequestType | ApproveDeviceSucceededType | ApproveDeviceFailedType;

export const approveDeviceRequest = (input: object) => ({ type: ApproveDeviceActions.APPROVE_DEVICE_REQUEST, input });

export const approveDeviceSucceeded = (payload: Device) => ({
  type: ApproveDeviceActions.APPROVE_DEVICE_SUCCEEDED,
  payload,
});

export const approveDeviceFailed = (error: Error) => ({
  type: ApproveDeviceActions.APPROVE_DEVICE_FAILED,
  error,
});
