export enum ListUsersActions {
  TOGGLE_LIST_USERS_MODAL_STATE = 'TOGGLE_LIST_USERS_MODAL_STATE',
  LIST_USERS_REQUEST = 'LIST_USERS_REQUEST',
  LIST_USERS_SUCCEEDED = 'LIST_USERS_SUCCEEDED',
  LIST_USERS_FAILED = 'LIST_USERS_FAILED',
}

export interface ToggleListUsersModalStateType {
  type: ListUsersActions.TOGGLE_LIST_USERS_MODAL_STATE;
}

export interface ListUsersRequestType {
  type: ListUsersActions.LIST_USERS_REQUEST;
  filter: object;
}

export interface ListUsersSucceededType {
  type: ListUsersActions.LIST_USERS_SUCCEEDED;
  payload: {
    total: number;
    users: Array<object>;
  };
}

export interface ListUsersFailedType {
  type: ListUsersActions.LIST_USERS_FAILED;
  error: Error;
}

export type ListUsersActionsType =
  | ListUsersRequestType
  | ListUsersSucceededType
  | ListUsersFailedType
  | ToggleListUsersModalStateType;

//Functions

export const toggleListUsersModalState = (): ToggleListUsersModalStateType => ({
  type: ListUsersActions.TOGGLE_LIST_USERS_MODAL_STATE,
});

export const listUsersRequest = (filter): ListUsersRequestType => ({
  type: ListUsersActions.LIST_USERS_REQUEST,
  filter,
});

export const listUsersSucceeded = (payload): ListUsersSucceededType => ({
  type: ListUsersActions.LIST_USERS_SUCCEEDED,
  payload,
});

export const listUsersFailed = (error: Error): ListUsersFailedType => ({
  type: ListUsersActions.LIST_USERS_FAILED,
  error,
});
