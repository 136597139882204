export enum UpdateUserActions {
  TOGGLE_UPDATE_USER_MODAL_STATE = 'TOGGLE_UPDATE_USER_MODAL_STATE',
  ON_CHANGE_USERNAME = 'ON_CHANGE_USERNAME',
  ON_CHANGE_USERSURNAME = 'ON_CHANGE_USERSURNAME',
  ON_CHANGE_AVATAR = 'ON_CHANGE_AVATAR',
  DELETE_AVATAR = 'DELETE_AVATAR',
  GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED',
  GET_USER_FAILED = 'GET_USER_FAILED',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED',
  UPDATE_USER_FAILED = 'UPDATE_USER_FAILED',
}

export interface ToggleUpdateUserModalStateType {
  type: UpdateUserActions.TOGGLE_UPDATE_USER_MODAL_STATE;
}

export interface OnChangeUsernameType {
  type: UpdateUserActions.ON_CHANGE_USERNAME;
  username: string;
}

export interface OnChangeUserSurnameType {
  type: UpdateUserActions.ON_CHANGE_USERSURNAME;
  userSurname: string;
}

export interface OnChangeAvatarType {
  type: UpdateUserActions.ON_CHANGE_AVATAR;
  url: string;
}

export interface DeleteAvatarType {
  type: UpdateUserActions.DELETE_AVATAR;
}

export interface GetUserSucceededType {
  type: UpdateUserActions.GET_USER_SUCCEEDED;
  payload: object;
}

export interface GetUserFailedType {
  type: UpdateUserActions.GET_USER_FAILED;
  error: Error;
}

export interface UpdateUserRequestType {
  type: UpdateUserActions.UPDATE_USER_REQUEST;
  id: string;
  input: {
    username: string;
    userType?: string;
    avatar: string;
    phoneNumber: string;
  };
}

export interface UpdateUserSucceededType {
  type: UpdateUserActions.UPDATE_USER_SUCCEEDED;
  payload: object;
}

export interface UpdateUserFailedType {
  type: UpdateUserActions.UPDATE_USER_FAILED;
  error: Error;
}

export type UpdateUserActionsType =
  | ToggleUpdateUserModalStateType
  | OnChangeUsernameType
  | OnChangeUserSurnameType
  | OnChangeAvatarType
  | DeleteAvatarType
  | GetUserSucceededType
  | GetUserFailedType
  | UpdateUserRequestType
  | UpdateUserSucceededType
  | UpdateUserFailedType;

export const toggleUpdateUserModalState = (): ToggleUpdateUserModalStateType => ({
  type: UpdateUserActions.TOGGLE_UPDATE_USER_MODAL_STATE,
});

export const onChangeUsername = (username: string): OnChangeUsernameType => ({
  type: UpdateUserActions.ON_CHANGE_USERNAME,
  username,
});
export const onChangeUserSurname = (userSurname: string): OnChangeUserSurnameType => ({
  type: UpdateUserActions.ON_CHANGE_USERSURNAME,
  userSurname,
});

export const onChangeAvatar = (url: string): OnChangeAvatarType => ({
  type: UpdateUserActions.ON_CHANGE_AVATAR,
  url,
});

export const deleteAvatar = (): DeleteAvatarType => ({ type: UpdateUserActions.DELETE_AVATAR });

export const updateUserRequest = (
  id: string,
  input: { username: string; userType?: string; avatar: string; phoneNumber: string },
): UpdateUserRequestType => ({ type: UpdateUserActions.UPDATE_USER_REQUEST, id, input });

export const updateUserSucceeded = (payload: object): UpdateUserSucceededType => ({
  type: UpdateUserActions.UPDATE_USER_SUCCEEDED,
  payload,
});

export const updateUserFailed = (error: Error): UpdateUserFailedType => ({
  type: UpdateUserActions.UPDATE_USER_FAILED,
  error,
});
