import { filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import {
    UpdateUserActions,
    UpdateUserActionsType,
    updateUserFailed,
    UpdateUserRequestType,
    updateUserSucceeded,
} from './actions';
import { updateUserMutation } from './queries';

export function updateUserEpic(action$) {
  return action$.pipe(
    filter((action: UpdateUserActionsType) => action.type === UpdateUserActions.UPDATE_USER_REQUEST),
    switchMap(({ id, input }: UpdateUserRequestType) =>
      updateUser(id, input).then(updateUserSucceeded).catch(updateUserFailed),
    ),
  );
}

export async function updateUser(
  id: string,
  input: { username: string; userType?: string; avatar: string; phoneNumber: string },
) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { updateUser: user },
  } = await graphQLClient.mutate({
    mutation: updateUserMutation,
    variables: { input: { id, ...input } },
  });

  return user;
}
