/*External dependencies */
import { gql } from '@apollo/client';

export const listUsersQuery = gql`
  query listUsers($input: ListUsersInput!) {
    listUsers(input: $input) {
      total
      users {
        __typename
        avatar
        email
        groupName
        id
        joinDate
        phoneNumber
        point
        status
        username
      }
    }
  }
`;
