export enum GetUserActions {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED',
  GET_USER_FAILED = 'GET_USER_FAILED',
}

export interface GetUserRequestType {
  type: GetUserActions.GET_USER_REQUEST;
  id: string;
}

interface GetUserSucceededType {
  type: GetUserActions.GET_USER_SUCCEEDED;
  payload: object;
}

interface GetUserFailedType {
  type: GetUserActions.GET_USER_FAILED;
  error: Error;
}

export type GetUserActionsType = GetUserRequestType | GetUserSucceededType | GetUserFailedType;

export const getUserRequest = (id: string) => ({ type: GetUserActions.GET_USER_REQUEST, id });

export const getUserSucceeded = (response: object) => ({ type: GetUserActions.GET_USER_SUCCEEDED, payload: response });

export const getUserFailed = (error: Error) => ({ type: GetUserActions.GET_USER_FAILED, error });
