/*External dependencies*/
import { filter, switchMap } from 'rxjs';

/*Local dependencies*/
import { setAuthenticatedClient } from '../../../../../../client/graphql';
import { ListDevicesActions, listDevicesFailed, ListDevicesRequestType, listDevicesSucceeded } from './actions';
import { listDevicesQuery } from './queries';

export function listDevicesEpic(action$) {
  return action$.pipe(
    filter((action: ListDevicesRequestType) => action.type === ListDevicesActions.LIST_DEVICES_REQUEST),
    switchMap(({ input }: ListDevicesRequestType) =>
      listDevices({ input }).then(listDevicesSucceeded).catch(listDevicesFailed),
    ),
  );
}

async function listDevices({ input }): Promise<object> {
  const qraphQLClient = await setAuthenticatedClient();
  console.log('listDevicesInput --->', input);
  const {
    data: {
      listDevices: { total, devices },
    },
  } = await qraphQLClient.query({
    query: listDevicesQuery,
    variables: {
      input,
    },
  });

  return { total, devices };
}
